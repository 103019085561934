export const MAX_OLDER_DATE_ALLOWED = 3
export const YEAR_FORMAT = 'yyyy'
export const MONTH_FORMAT = 'MM'
export const DAY_FORMAT = 'dd'
export const RESIDENT_SINCE_DATE_FORMAT = `${YEAR_FORMAT}-${MONTH_FORMAT}-${DAY_FORMAT}`

export enum MONTHS {
  MIN = 0,
  FIRST_MONTH = 1,
  MAX = 12
}

export const NULL_FUNC = () => null
export const YEAR_NUMBER_OF_DIGITS = 4
export const MONTH_NUMBER_OF_DIGITS = 2
export const RESIDENT_SINCE_DATE_FIELD_REGEX = /[0-9]/
export const RESIDENT_SINCE_YEAR_FIELD_PLACEHOLDER = 'JJJJ'
export const RESIDENT_SINCE_MONTH_FIELD_PLACEHOLDER = 'MM'
export const AFFILIATE = 'affiliate'
export const SMAVA_APP = 'smava_app'

export const PATH_INPUT_MAX_LENGTH = 64
export const PATH_INPUT_BANK_ACCOUNT_DATA_MAX_LENGTH = 22
export const PATH_AMOUNT_INPUT_MAX_LENGTH = 7
export const PATH_AMOUNT_INPUT_EXTENDED_MAX_LENGTH = 9
export const PATH_PHONE_INPUT_MAX_LENGTH = 32
export const PATH_ZIP_CITY_INPUT_MAX_LENGTH = 10
export const MIN_AGE = 18
export const YOUNG_AGE = 34
export const MIDDLE_AGE = 55
export const FILTER_EMOJI_REGEX =
  /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
