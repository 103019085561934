import { PropsWithChildren } from 'react'
import {
  createInstance,
  logOnlyEventDispatcher,
  OptimizelyProvider
} from '@optimizely/react-sdk'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import optimizelyErrorHandler from 'utils/tracking/optimizely/errorHandler'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { isMarketingConfig } from 'config/utils/config'
import { useData } from 'stores/utils/hooks/useData'
import { useUrlParams } from 'stores/utils/hooks/useUrlParams'
import { isApp } from 'utils/formDependencies/conditions/isApp'

// Relies on continous internet connection in contrast to the datafile approach
export const optimizelyClient = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
  errorHandler: optimizelyErrorHandler,
  eventDispatcher: logOnlyEventDispatcher
})

export enum Experiments {
  CAT_2739_DAC_6_ABORTED_LEADS = '2024-06-01_cat-2739-dac-6-finalizing-aborted-leads_v2',
  CAT_2781_DAC_6_ABORTED_LEADS_FFG = '2024-06-12_cat-2781_dac_6_finalizing_aborted_leads',
  CAT_2929_TPL_2_NEW_UI_FFG = '2024_08_05_cat_2929_tpl_2',
  CAT_3025_DAC_7_AGE_GROUP_FFG = '2024-09-20_cat-3025_dac_7_age_group_ffg',
  CAT_3012_NEWSLETTER = '2024_09_13_cat_3012_newsletter_abc_test',
  CAT_3026_DAC_7_BACK_BUTTON_TEST = '2024-09-20_cat-3026_dac_7_back_button_test'
}

const CustomOptimizelyProvider = ({ children }: PropsWithChildren<unknown>) => {
  const urlParams = useUrlParams()

  const { data } = useData()

  const isOptimizelyDisabled =
    !isNotAffiliate(urlParams?.context) ||
    isMarketingConfig(urlParams.formConfig) ||
    isApp(urlParams?.isAppContext)

  if (isOptimizelyDisabled) {
    return <>{children}</>
  }

  const userId = getCookie(COOKIE_KEY_FCID) || ''

  optimizelyClient.setUser({
    id: userId,
    attributes: { ...getOptimizelyTrackingAttribute(data) }
  })

  optimizelyClient
    .onReady()
    .then(() => (
      <OptimizelyProvider optimizely={optimizelyClient}>
        {children}
      </OptimizelyProvider>
    ))

  return <>{children}</>
}

export default CustomOptimizelyProvider
