import { memo } from 'react'

import CreateFinalAfterInactivity, {
  InactivityType,
  LONG_TIMEOUT,
  SHORT_TIMEOUT
} from './containers/CreateFinalAfterInactivity'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import {
  CONFIG_DDF_MARKETING,
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_DDF_SMAVA_SEPARATED_DAC,
  isConfigWithoutInactivityRedirection
} from 'config/utils/config'
import { Experiments } from 'CustomOptimizelyProvider'
import { PossibleVariants } from 'utils/tracking/optimizely/types'
import { getAge } from 'utils/date'
import { MIN_AGE, YOUNG_AGE } from 'constants/components'

type InactivityProps = {
  handleSubmit: () => void
  isSmava: boolean
}

const InactivityContainer = ({ handleSubmit, isSmava }: InactivityProps) => {
  const {
    rootStore: {
      page: { config },
      traversal: {
        data: {
          debtors: {
            primary: {
              personal: { birthday }
            }
          }
        }
      },
      experimentData
    }
  } = useRootStore()

  const isCreateFinalAfterInactivityFeatureEnabled =
    !isConfigWithoutInactivityRedirection(config.name)

  if (!isCreateFinalAfterInactivityFeatureEnabled) {
    return null
  }

  const isTouchedIgnored =
    config.name === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
    config.name === CONFIG_DDF_SEPARATED_DAC

  let timeout = SHORT_TIMEOUT

  const variationDac7FFG =
    experimentData[Experiments.CAT_3025_DAC_7_AGE_GROUP_FFG]

  const isVariationBofDac7 = variationDac7FFG === PossibleVariants.VARIATION_B

  const debtorsAge = getAge(birthday)

  if (
    (isVariationBofDac7 && debtorsAge >= MIN_AGE && debtorsAge < YOUNG_AGE) ||
    config.name === CONFIG_DDF_MARKETING ||
    (isSmava && debtorsAge >= MIN_AGE && debtorsAge < YOUNG_AGE)
  ) {
    timeout = 180000
  }

  return (
    <>
      <CreateFinalAfterInactivity
        timeout={timeout}
        inactivityType={InactivityType.PAYOUT_ACCOUNT_INACTIVITY}
        onNext={handleSubmit}
        isTouchedIgnored={isTouchedIgnored}
      />
      <CreateFinalAfterInactivity
        timeout={LONG_TIMEOUT}
        inactivityType={InactivityType.DAC_INACTIVITY}
        onNext={handleSubmit}
        isTouchedIgnored={isTouchedIgnored}
      />
    </>
  )
}

export default memo(InactivityContainer)
