import {
  FormEvent,
  memo,
  PropsWithChildren,
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react'
import { toJS } from 'mobx'
import {
  NavigateFunction,
  Route,
  Routes as ReactRouterRoutes
} from 'react-router-dom'
import { Form } from 'react-final-form'
import { FormSubscription } from 'final-form'
import Bugsnag from '@bugsnag/js'
import { executeAsync } from 'utils/performance'
import { trackPageView } from 'utils/tracking'
import { TraversalError } from 'interfaces/ApolloResponse.interface'
import VersionDisplay from 'components/VersionDisplay'
import TemporarySkeleton from 'components/SkeletonView/TemporarySkeleton'
import { getFooterSeals } from 'components/Footer/getSeals'
import Seals from 'components/Footer/Seals'
import {
  setGlobalEventProperty,
  trackInAmplitude
} from 'utils/tracking/amplitude'
import { FooterPortalContainer } from 'components/Portal/Footer'
import ReturningVisitor from 'components/ReturningVisitor'
import { useClearError, useSetError } from 'context/Error/hook'
import { ConfigOptions } from 'config/config.interface'
import {
  CONFIG_DDF_SEPARATED_DAC,
  CONFIG_DDF_SMAVA_SEPARATED_DAC,
  getDisplayedPages
} from 'config/utils/config'
import Page from 'containers/Page'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import InitialSkeleton from 'components/SkeletonView/InitialSkeleton'
import {
  AmplitudeEvent,
  ErrorType,
  EventProperty
} from 'utils/tracking/amplitude/amplitude.interface'
import { AffiliateEventName, sendAffiliateEvent } from 'utils/affiliate'
import { useStores } from 'stores/utils/hooks/useStores'
import ErrorFallback from 'containers/ErrorFallback'
import { LoadingMode } from 'components/LoadingProvider'
import SSL from 'components/Footer/SSL'
import Divider from 'components/Footer/Divider'
import Benefits from 'components/Footer/Benefits'
import PageLayout from 'components/Layout/Page'
import ProgressIndicator from 'components/ProgressIndicator'
import DDFTrustCards from 'containers/Smava/DDFTrustCards'
import QueryParamsUpdater from 'containers/QueryParamsUpdater'
import { isTenantSmava } from 'utils/url'
import isNotMortgage from 'utils/formDependencies/conditions/isNotMortgage'
import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { AxiosError } from 'axios'
import pollard from 'utils/pollard'
import { appEnvironment } from 'utils/env'
import InitialSmavaDDFSkeleton from 'components/SkeletonView/InitialSmavaDDFSkeleton'
import isNotAffiliate from 'utils/formDependencies/conditions/isNotAffiliate'
import { getPageNameFromPath } from 'utils/pages'
import { useUrlParams } from 'stores/utils/hooks/useUrlParams'
import { PageName } from 'stores/helpers/Navigation.interface'
import { FormNavigationSwitcher } from 'components/FormNavigation'
import InactivityContainer from 'InactivityContainer'

interface RouteProps {
  options: ConfigOptions | undefined
  navigate: NavigateFunction
}

const isAddUserPropertiesOnPut = isTenantSmava()

const RouteElement = memo(({ options, navigate }: RouteProps) => {
  if (!options) {
    return <>{ErrorFallback}</>
  }

  return <Page navigate={navigate} options={options} />
})
RouteElement.displayName = 'RouteElement'

const formSubscriptions: FormSubscription = {
  submitting: true,
  pristine: true
}

const MORTGAGE_REDIRECT_URL_HOST = 'https://baufi.smava.de'

type RoutesProps = {
  navigate: NavigateFunction
}

const Routes = ({ navigate }: PropsWithChildren<RoutesProps>) => {
  const { rootStore } = useRootStore()
  const isSmava = isTenantSmava()
  // string is deprecated, please use PageName
  const [currentPage, setCurrentPage] = useState<string | undefined | PageName>(
    undefined
  )
  const urlParams = useUrlParams()

  const {
    page: {
      config,
      isOnTermsAndConditionsPage,
      isLastDisplayedPage,
      getCurrentIndex
    },
    navigation: {
      goToNextPage,
      goToPreviousPage,
      parentUrl,
      currentPageInSession
    },
    traversal,
    fieldErrors,
    history
  } = useStores()

  const isLastPage = isLastDisplayedPage(currentPageInSession)

  const parentFrameStartLoadTimeCallback = (timestamp: number) => {
    trackInAmplitude(AmplitudeEvent.ParentFrameStartLoadTime, {
      timestamp
    })
  }

  useEffect(() => {
    pollard.listenTo(
      RegisteredEventName.parentFrameStartLoadDate,
      parentFrameStartLoadTimeCallback
    )
  }, [])

  useEffect(() => {
    Bugsnag.setContext(currentPage || '')
  }, [currentPage])

  useEffect(() => {
    if (!currentPage) {
      return
    }

    setGlobalEventProperty(EventProperty.CurrentPage, currentPage)
    trackInAmplitude(AmplitudeEvent.ViewPage)
    trackPageView(rootStore)
    sendAffiliateEvent(AffiliateEventName.Pageview, traversal, rootStore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const preventSubmit = useCallback((e: FormEvent) => {
    e.preventDefault()
  }, [])

  const initialValues = useMemo(() => toJS(traversal.data), [traversal.data])

  const setError = useSetError()
  const clearError = useClearError()

  history.listen(({ location }) => {
    const currentPageName = getPageNameFromPath(location.pathname)
    setCurrentPage(currentPageName)
    // TODO: Can be moved to NavigationContext later..
    clearError()
  })

  if (!config.version || !currentPage) {
    if (isSmava) {
      return <InitialSmavaDDFSkeleton />
    }

    return <InitialSkeleton />
  }

  const getMortgageRedirectUrl = () => {
    if (!parentUrl) {
      return MORTGAGE_REDIRECT_URL_HOST
    }

    const url = new URL(parentUrl as string)
    const searchParams = new URLSearchParams(url.search)

    /* deleting amount category and term from the query since we will add it from the value in taurine */
    searchParams.delete('amount')
    searchParams.delete('category')
    searchParams.delete('duration')
    searchParams.delete('term')
    searchParams.delete('purpose')

    return `${MORTGAGE_REDIRECT_URL_HOST}?amount=${
      traversal.data.common.amount
    }&category=${traversal.data.common.purpose}&duration=${
      traversal.data.common.term
    }&${searchParams.toString()}`
  }

  const isNotAffiliateBoolean = isNotAffiliate(urlParams?.context)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = async (_: any, form: any) => {
    const shouldBroadcastRedirectEvent =
      isSmava &&
      !isNotMortgage(traversal.data, urlParams?.context) &&
      (currentPage === 'contact' || currentPage === 'base')

    trackInAmplitude(AmplitudeEvent.CtaIsClicked)

    rootStore.setLoadingMode(LoadingMode.Blocking)

    if (shouldBroadcastRedirectEvent) {
      broadcastToParent({
        eventName: RegisteredEventName.redirect,
        data: {
          url: getMortgageRedirectUrl()
        }
      })

      return null
    }

    try {
      if (await isOnTermsAndConditionsPage()) {
        /* calling traversal patch directly so that we can await before the next put call */
        await traversal.patch([
          { op: 'set', path: '/common/hasAcceptedTerms', value: true }
        ])

        traversal.acceptTermsAndConditions()
      }
      await fieldErrors.setFieldErrorsFromAxiosResponse(await traversal.put())

      const errors = await fieldErrors.getErrors(currentPage)
      const erroredPaths = Object.keys(errors)
      const previousErrorPageName = await fieldErrors.findFirstErrorPage(
        getCurrentIndex()
      )

      if (erroredPaths.length > 0) {
        executeAsync(() => {
          trackInAmplitude(AmplitudeEvent.ValidateTraversal, {
            type: ErrorType.Page,
            page: currentPage,
            errors: erroredPaths.join(',')
          })

          for (const errorPath of erroredPaths) {
            const traversalErrors = errors[errorPath]
            if (!traversalErrors) {
              continue
            }

            trackInAmplitude(AmplitudeEvent.ValidateTraversal, {
              type: ErrorType.Field,
              path: errorPath,
              errors: traversalErrors
                .map(({ id }: TraversalError) => id)
                .join(',')
            })
          }
        })

        const visibleFieldPaths = form.getRegisteredFields()
        const visibleAndErrored = erroredPaths.filter(
          (x) => !visibleFieldPaths.includes(x)
        )

        visibleAndErrored.forEach((path) => delete errors[path])

        rootStore.setLoadingMode(LoadingMode.None)

        return errors
      }

      if (previousErrorPageName) {
        rootStore.setLoadingMode(LoadingMode.None)

        const errorsOnFirstBrokenPage = await fieldErrors.getErrors(
          previousErrorPageName
        )
        return errorsOnFirstBrokenPage
      }

      if (isAddUserPropertiesOnPut) {
        rootStore.traversal.setUserPropertiesOnPut()
      }
      await goToNextPage(navigate)
    } catch (error) {
      rootStore.setLoadingMode(LoadingMode.None)
      const axiosError = error as AxiosError
      setError(axiosError)
      trackInAmplitude(AmplitudeEvent.ErrorOccurred, {
        error: axiosError.message
      })
    }

    return {}
  }

  return (
    <>
      {isNotAffiliateBoolean && isSmava && <QueryParamsUpdater />}
      <Form
        subscription={formSubscriptions}
        initialValues={initialValues}
        validateOnBlur
        // TODO: Refactor `onSubmit` method and add tests.
        onSubmit={onSubmit}
      >
        {({ handleSubmit, form }) => {
          traversal.setFormApi(form)

          return (
            <PageLayout onSubmit={preventSubmit}>
              {!(
                (config.name === CONFIG_DDF_SMAVA_SEPARATED_DAC ||
                  config.name === CONFIG_DDF_SEPARATED_DAC) &&
                currentPage === 'dac'
              ) && (
                <ProgressIndicator
                  withMotivationalText={!isSmava}
                  isSlimProgress={isSmava}
                  isWithMotivationalTextBelow={isSmava}
                />
              )}
              <ReturningVisitor />
              <ReactRouterRoutes>
                {getDisplayedPages(rootStore).map(([pageName, options]) => (
                  <Route
                    key={pageName}
                    path={pageName}
                    element={
                      // We have to make sure to use the same navigate instance all over the app
                      <RouteElement options={options} navigate={navigate} />
                    }
                  />
                ))}
                <Route path="*" element={<TemporarySkeleton />} />
              </ReactRouterRoutes>
              {isLastPage && (
                <InactivityContainer
                  handleSubmit={handleSubmit}
                  isSmava={isSmava}
                />
              )}

              <FormNavigationSwitcher
                isSmava={isSmava}
                onNext={handleSubmit}
                onBack={() => goToPreviousPage(navigate)}
                navigate={navigate}
                isOfferLabel={isLastPage}
              />
              <FooterPortalContainer />
            </PageLayout>
          )
        }}
      </Form>
      {!isSmava &&
        !(
          config.name === CONFIG_DDF_SEPARATED_DAC && currentPage === 'dac'
        ) && (
          <>
            <SSL />
            <Divider />
            <Benefits />
            <Divider />
            <Seals
              seals={getFooterSeals(!isNotAffiliateBoolean)}
              itemMargin={16}
            />
          </>
        )}
      {isSmava &&
        !(
          config.name === CONFIG_DDF_SMAVA_SEPARATED_DAC &&
          currentPage === 'dac'
        ) && <DDFTrustCards isNotAffiliate={isNotAffiliateBoolean} />}
      {config.rateHint && <config.rateHint />}
      {appEnvironment !== 'production' && <Divider />}
      <VersionDisplay />
    </>
  )
}

export default memo(Routes)
